@import 'styles/variables.less';

.inputField {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  .inputFieldInfo {
    color: @gray-7;
    font-size: 0.8rem;
  }
  .allowedRange {
    display: flex;
    justify-content: space-between;
  }
  .defaultValue {
    display: flex;
    justify-content: flex-end;
  }
}

.textInput {
  margin-top: 24px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);