.FixIcon {
  display: inline !important;
  font-size: 16px !important;
  line-height: 22px !important;
  vertical-align: baseline !important;
}
.FixIcon > svg {
  vertical-align: baseline !important;
}

.True {
  color: green !important;
}

.False {
  color: red !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);