.enableDigitalSignatureSwitch {
  margin-bottom: 24px;
}
.tenantSigneeSelection {
  margin-top: 24px;
}

.bookmarkedItemAlert {
  margin-top: 24px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);